<template>
  <div class="info-style">
    <div class="bigbox">
      <div class="box1">{{$t('text.t56')}}</div>
      <div class="bigbox-t1 bigbox-mb10">{{$t('text.t57')}}</div>
      <div class="bb-mb20">
        <van-uploader
          :max-count="1"
          :after-read="afterRead1"
          v-model="fileList"
          multiple
          @delete="deleteImg"
        />
      </div>
      <div class="bigbox-t1 bigbox-mb10">{{$t('text.t58')}}</div>
      <div class="bb-mb20">
        <van-uploader
          :max-count="1"
          :after-read="afterRead2"
          v-model="fileList1"
          multiple
          @delete="deleteImg2"
        />
      </div>
      <div class="bigbox-t1 bigbox-mb10">{{$t('text.t59')}}</div>
      <div class="bb-inp bb-mb20">
        <el-input v-model="nickname" :placeholder="$t('text.t60')"></el-input>
      </div>
      <div class="bigbox-t1 bigbox-mb10">{{$t('text.t61')}}</div>
      <div class="bb-inp bb-mb20">
        <el-input
          type="textarea"
          v-model="intro"
          :placeholder="$t('text.t62')"
        ></el-input>
      </div>
      <!-- <div class="bigbox-t1 bigbox-mb10">{{$t('text.t63')}}</div>
      <div class="bb-inp bb-mb20">
        <el-input v-model="email" :placeholder="$t('text.t64')"></el-input>
      </div> -->
      <!-- <div class="bigbox-t1 bigbox-mb10">{{$t('text.t65')}}</div>
      <div class="bb-inp bb-mb20">
        <el-input disabled v-model="address">
          <template #suffix>
            <div class="bb-inp-one center-center" @click="copy(copytext)">{{$t('text.t66')}}</div>
          </template>
        </el-input>
      </div> -->
      <div class="bb-btn">
        <van-button :loading-text="$t('text.t67')" :loading="isloading" block color="#323232" @click="submit">{{$t('text.t68')}}</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState,mapActions} from 'vuex'
export default {
  components: {},
  data() {
    return {
      fileList: [],
      upimg:'',
      fileList1: [],
      upimg2:'',
      nickname: "",
      intro: "",
      email: "",
      address: "",
      copytext:'',
      type:'',
      isloading:false,
    };
  },
  created(){
    this.getuserinfo()
  },
  computed: {
    ...mapState(["language"]),
  },
  mounted() {},
  methods: {
    ...mapActions(["getUser"]),
    submit(){
      if(!this.upimg){
        this.$toast(this.$t('text.t69'))
        return
      }
      if(!this.upimg2){
        this.$toast(this.$t('text.t70'))
        return
      }
      if(!this.nickname){
        this.$toast(this.$t('text.t71'))
        return
      }
      if(!this.intro){
        this.$toast(this.$t('text.t72'))
        return
      }
      // if(!this.email){
      //   this.$toast(this.$t('text.t73'))
      //   return
      // }
      this.isloading = true
      let arg = {
        avatar:this.upimg,
        bannerimage:this.upimg2,
        nickname:this.nickname,
        intro:this.intro,
        email:'',
        language:this.language
      }
      this.$http.post('/collection/userEdit',arg).then(res=>{
        this.$toast(res.msg)
        this.isloading = false
        this.getUser()
        this.$router.go(-1)
      }).catch(()=>{
        this.isloading = false
      })
    },
    getuserinfo(){
      this.$http.get('/index/refresh').then(res=>{
        const {nickname,intro,email,username,avatar,bannerimage} = res.data
        this.upimg = avatar
        this.upimg2 = bannerimage
        this.nickname = nickname
        this.intro = intro
        // this.email = email
        this.address = username.substr(0,11) + '...' + username.substr(username.length - 10)
        this.copytext = username
      })
    },
    afterRead1(file){
      this.type = 1
      this.afterReadImg(file)
    },
    afterRead2(file){
      this.type = 2
      this.afterReadImg(file)
    },
    afterReadImg(file) {
      file.status = "uploading";
      file.message = this.$t('text.t74');
      let ff = new FormData();
      ff.append("file", file.file);
      this.$http.upload("/common/upload", ff).then((res) => {
        this.type==1?this.upimg = res.data.url:this.type==2?this.upimg2 = res.data.url : ''
        setTimeout(() => {
          file.status = "done";
          file.message = this.$t('text.t75');
        }, 1000);
      }).catch(()=>{
        setTimeout(() => {
          file.status = "failed";
          file.message = this.$t('text.t76');
        }, 1000);
      })
    },
    deleteImg(){
      this.upimg = ''
    },
    deleteImg2(){
      this.upimg2 = ''
    },
  },
};
</script>

<style lang="less">
.info-style {
  .bigbox {
    width: 321px;
    padding: 41px 0;
    margin: auto;
    .box1 {
      text-align: center;
      margin-bottom: 25px;
      font-size: 18px;
      font-weight: bold;
      color: #323232;
    }
    .bigbox-t1 {
      font-size: 15px;
      font-weight: bold;
      color: #323232;
    }
    .bigbox-t2 {
      font-size: 12px;
      font-weight: bold;
      color: #323232;
    }
    .bigbox-mb10 {
      margin-bottom: 10px;
    }
    .bb-mb20 {
      margin-bottom: 20px;
    }
    .bb-inp {
      width: 100%;
      .el-select {
        display: block;
      }
      .bb-inp-one {
        font-size: 12px;
        color: #323232;
        height: 100%;
      }
    }
    .bb-btn {
      width: 300px;
      margin: auto;
      padding-top: 28px;
      .van-button {
        border-radius: 5px;
      }
    }
  }
}
</style>